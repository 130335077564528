// import React from "react";
// import Sidebar from "./Sidebar";
// import HeroSection from "./HeroSection";
// import AboutMe from "./AboutMe";
// import Services from "./Services";
// import Resume from "./Resume";
// import Portfolio from "./Portfolio";
// import Testimonial from "./Testimonial";
// import Contact from "./Contact";
// import Certificates from "./Certificates";

// const HomePage = () => {
//   return (
//     <div className="flex flex-col md:flex-row overflow-x-auto">
//       {/* Sidebar */}
//       <div className="md:w-1/5 w-full  bg-neutral-900 ">
//         <Sidebar />
//       </div>
//       {/* Header */}
//       <div className="md:w-4/5 w-full bg-neutral-500 ">
//         <HeroSection />
//         <AboutMe />
//         <Services />
//         <Resume />
//         <Portfolio />
//         <Certificates />
//         <Testimonial />
//         <Contact />
//       </div>
//     </div>
//   );
// };

// export default HomePage;

// import React, { useRef } from "react";
// import Sidebar from "./Sidebar";
// import HeroSection from "./HeroSection";
// import AboutMe from "./AboutMe";
// import Services from "./Services";
// import Resume from "./Resume";
// import Portfolio from "./Portfolio";
// import Testimonial from "./Testimonial";
// import Contact from "./Contact";
// import Certificates from "./Certificates";
// import Bot from "../Components/Bot";

// const HomePage = () => {
//   // Create refs for each section
//   const heroRef = useRef(null);
//   const aboutMeRef = useRef(null);
//   const servicesRef = useRef(null);
//   const resumeRef = useRef(null);
//   const portfolioRef = useRef(null);
//   const certificatesRef = useRef(null);
//   const testimonialRef = useRef(null);
//   const contactRef = useRef(null);

//   // Scroll to the selected section
//   const scrollToSection = (menuItem) => {
//     switch (menuItem) {
//       case "Home":
//         heroRef.current?.scrollIntoView({ behavior: "smooth" });
//         break;
//       case "About Me":
//         aboutMeRef.current?.scrollIntoView({ behavior: "smooth" });
//         break;
//       case "What I Do":
//         servicesRef.current?.scrollIntoView({ behavior: "smooth" });
//         break;
//       case "Resume":
//         resumeRef.current?.scrollIntoView({ behavior: "smooth" });
//         break;
//       case "Portfolio":
//         portfolioRef.current?.scrollIntoView({ behavior: "smooth" });
//         break;
//       case "Certificates":
//         certificatesRef.current?.scrollIntoView({ behavior: "smooth" });
//         break;
//       case "Testimonial":
//         testimonialRef.current?.scrollIntoView({ behavior: "smooth" });
//         break;
//       case "Contact":
//         contactRef.current?.scrollIntoView({ behavior: "smooth" });
//         break;
//       default:
//         break;
//     }
//   };

//   return (
//     <div className="flex flex-col md:flex-row overflow-x-auto">
//       <Bot />
//       <div className="md:w-1/5 w-full bg-neutral-900">
//         {/* Pass scroll function to Sidebar */}
//         <Sidebar onMenuClick={scrollToSection} />
//       </div>
//       <div className="md:w-4/5 w-full bg-neutral-500">
//         <div ref={heroRef}>
//           <HeroSection />
//         </div>
//         <div ref={aboutMeRef}>
//           <AboutMe />
//         </div>
//         <div ref={servicesRef}>
//           <Services />
//         </div>
//         <div ref={resumeRef}>
//           <Resume />
//         </div>
//         <div ref={portfolioRef}>
//           <Portfolio />
//         </div>
//         <div ref={certificatesRef}>
//           <Certificates />
//         </div>
//         <div ref={testimonialRef}>
//           <Testimonial />
//         </div>
//         <div ref={contactRef}>
//           <Contact />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomePage;

import React, { useRef, useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import HeroSection from "./HeroSection";
import AboutMe from "./AboutMe";
import Services from "./Services";
import Resume from "./Resume";
import Portfolio from "./Portfolio";
import Testimonial from "./Testimonial";
import Contact from "./Contact";
import Certificates from "./Certificates";
import Bot from "../Components/Bot";

const HomePage = () => {
  // Create refs for each section
  const heroRef = useRef(null);
  const aboutMeRef = useRef(null);
  const servicesRef = useRef(null);
  const resumeRef = useRef(null);
  const portfolioRef = useRef(null);
  const certificatesRef = useRef(null);
  const testimonialRef = useRef(null);
  const contactRef = useRef(null);

  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Scroll to the selected section
  const scrollToSection = (menuItem) => {
    switch (menuItem) {
      case "Home":
        heroRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "About Me":
        aboutMeRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "What I Do":
        servicesRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "Resume":
        resumeRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "Portfolio":
        portfolioRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "Certificates":
        certificatesRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "Testimonial":
        testimonialRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "Contact":
        contactRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col md:flex-row overflow-x-auto">
      <Bot />
      <div className="md:w-1/5 w-full bg-neutral-900">
        {/* Pass scroll function to Sidebar */}
        <Sidebar onMenuClick={scrollToSection} />
      </div>
      <div className="md:w-4/5 w-full bg-neutral-500">
        <div ref={heroRef}>
          <HeroSection />
        </div>
        <div ref={aboutMeRef}>
          <AboutMe />
        </div>
        <div ref={servicesRef}>
          <Services />
        </div>
        <div ref={resumeRef}>
          <Resume />
        </div>
        <div ref={portfolioRef}>
          <Portfolio />
        </div>
        <div ref={certificatesRef}>
          <Certificates />
        </div>
        <div ref={testimonialRef}>
          <Testimonial />
        </div>
        <div ref={contactRef}>
          <Contact />
        </div>
      </div>
      {/* {showScrollButton && (
        <button
          className="fixed bottom-10 right-10 bg-[#cb8161] text-white font-bold text-3xl py-1 px-3 "
          onClick={scrollToTop}
        >
          ^
        </button>
      )} */}
      {showScrollButton && (
        <button
          className="fixed bottom-20 right-6 bg-orange-600 text-white font-bold text-3xl py-2 px-4 rounded-full "
          onClick={scrollToTop}
        >
          ^
        </button>
      )}
    </div>
  );
};

export default HomePage;

// import React, { useState, useEffect } from "react";
// import Sidebar from "./Sidebar";
// import HeroSection from "./HeroSection";
// import AboutMe from "./AboutMe";
// import Services from "./Services";
// import Resume from "./Resume";
// import Portfolio from "./Portfolio";
// import Testimonial from "./Testimonial";
// import Contact from "./Contact";
// import Certificates from "./Certificates";
// import Bot from "../Components/Bot";

// const HomePage = () => {
//   const [showScrollButton, setShowScrollButton] = useState(false);
//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 100) {
//         setShowScrollButton(true);
//       } else {
//         setShowScrollButton(false);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   };

//   return (
//     <div className="flex flex-col md:flex-row overflow-hidden">
//       <Bot />
//       <div className="md:w-1/5 w-full bg-neutral-900">
//         {/* Pass scroll function to Sidebar */}
//         <Sidebar />
//       </div>
//       <div className="md:w-4/5 w-full bg-neutral-500">
//         <HeroSection />
//         <AboutMe />
//         <Services />
//         <Resume />
//         <Portfolio />
//         <Certificates />
//         <Testimonial />
//         <Contact />
//         {showScrollButton && (
//           <button
//             className="fixed bottom-20 right-6 bg-orange-600 text-white font-bold text-3xl py-2 px-4 rounded-full "
//             onClick={scrollToTop}
//           >
//             ^
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default HomePage;
