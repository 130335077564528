// import React from "react";
// import { FaStar, FaRegStar } from "react-icons/fa";
// import man1 from "../Assets/man1.png"; // Import the image

// const TestimonialCard = () => {
//   return (
//     <div className="bg-gray-100 flex flex-col p-2 md:p-0 md:flex-row items-center shadow-lg max-w-3xl border border-gray-300 rounded-md md:rounded-l-full mx-auto relative">
//       {/* Fully Rounded Profile Image */}
//       <div className="absolute md:static top-0 left-4 w-20 h-20 sm:w-40 sm:h-40 rounded-full overflow-hidden flex-shrink-0 z-10 -mt-10 md:mt-0">
//         <img src={man1} alt="Profile" className="w-full h-full object-cover" />
//       </div>
//       {/* Testimonial Content */}
//       <div className="ml-4 sm:ml-6 flex-1">
//         <h3 className="font-semibold text-gray-800 text-center md:text-start text-base sm:text-lg">
//           Sumit Kapoor
//         </h3>
//         <p className="text-gray-600 text-xs sm:text-sm leading-relaxed mt-6 md:mt-2">
//           Lorem ipsum is a dummy or placeholder text commonly used in graphic
//           design, publishing, and web development to fill empty spaces in a
//           layout that do not yet have content.
//         </p>

//         {/* Star Ratings */}
//         <div className="flex mt-3 justify-center sm:justify-start">
//           <FaStar className="text-yellow-400" size={18} />
//           <FaStar className="text-yellow-400" size={18} />
//           <FaStar className="text-yellow-400" size={18} />
//           <FaRegStar className="text-gray-400" size={18} />
//           <FaRegStar className="text-gray-400" size={18} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TestimonialCard;

//finally done
import React from "react";
import { FaStar, FaRegStar } from "react-icons/fa";

const TestimonialCard = ({ name, testimonial, image, rating }) => {
  // Generate the star ratings dynamically based on the rating prop
  const filledStars = Array(rating).fill(
    <FaStar className="text-yellow-400" size={18} />
  );
  const emptyStars = Array(5 - rating).fill(
    <FaRegStar className="text-gray-400" size={18} />
  );

  return (
    <div className="relative bg-gray-100 flex items-start md:mb-0 mb-16 flex-col p-2 md:p-0 md:flex-row  shadow-lg border border-gray-300 rounded-md md:rounded-l-full ">
      {/* Fully Rounded Profile Image */}
      <div
        className="absolute md:static -top-12 left-4 w-20 h-20 md:w-40 md:h-40 rounded-full border border-gray-300 bg-cover bg-center"
        style={{ backgroundImage: `url(${image})` }}
      ></div>

      {/* Testimonial Content */}
      <div className=" flex-1">
        <h3 className="ml-10 md:ml-6 mt-0 md:mt-2 font-semibold text-gray-800 text-center md:text-start text-base sm:text-lg">
          {name}
        </h3>
        <p className="ml-0 md:ml-6 text-gray-600 text-xs sm:text-sm leading-relaxed mt-4 md:mt-2">
          {testimonial}
        </p>

        {/* Star Ratings */}
        <div className="ml-0 md:ml-6 flex mt-3 justify-center sm:justify-start">
          {filledStars}
          {emptyStars}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
