import React from "react";
import TestimonialSlider from "./TestimonialSlider";

const Testimonial = () => {
  return (
    <div className="font font-Poppins bg-[#343A40] h-full pb-8 px-6 md:px-0">
      {/* text overlay effect of main heading*/}
      <div className="flex flex-col items-center relative pt-20">
        {/* Background Text */}
        <h1 className="absolute text-6xl md:text-9xl font-semibold text-white opacity-5">
          TESTIMONIAL
        </h1>
        <div className="relative flex flex-col items-center mt-5 md:mt-12">
          {/* Foreground Text */}
          <h1 className="text-2xl md:text-4xl font-semibold text-gray-100">
            Client Speak
          </h1>
          {/* Custom Border */}
          <div className="md:w-28 w-14 md:h-[4px] h-[2px] bg-orange-600 mt-2 mb-10"></div>
        </div>
      </div>
      {/* main content section */}
      <TestimonialSlider />
    </div>
  );
};

export default Testimonial;
