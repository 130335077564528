// import React from "react";
// import man1 from "../Assets/man1.png";
// import man2 from "../Assets/man2.png";

// const VerticalCardSlider = () => {
//   return (
//     <div className="wrapper">
//       <div className="outer">
// {/* card  1*/}
// <div className="card" style={{ "--delay": "-1" }}>
//   <div className="content">
//     <div className="img">
//       <img src={man1} alt="Sumit Kapoor" />
//     </div>
//     <div className="details">
//       <span className="name">Sumit Kapoor</span>
//       <p className="text-sm">
//         Highly skilled web developer, great results every time!
//       </p>
//     </div>
//   </div>
//   {/* <!-- rating stars --> */}
//   <div className="flex items-center">
//     <svg
//       className="w-4 h-4 text-yellow-300"
//       aria-hidden="true"
//       xmlns="http://www.w3.org/2000/svg"
//       fill="currentColor"
//       viewBox="0 0 22 20"
//     >
//       <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//     </svg>
//     <svg
//       className="w-4 h-4 text-yellow-300"
//       aria-hidden="true"
//       xmlns="http://www.w3.org/2000/svg"
//       fill="currentColor"
//       viewBox="0 0 22 20"
//     >
//       <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//     </svg>
//     <svg
//       className="w-4 h-4 text-yellow-300"
//       aria-hidden="true"
//       xmlns="http://www.w3.org/2000/svg"
//       fill="currentColor"
//       viewBox="0 0 22 20"
//     >
//       <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//     </svg>
//   </div>
// </div>
//         {/* card  2*/}
//         <div className="card" style={{ "--delay": "0" }}>
//           <div className="content">
//             <div className="img">
//               <img src={man2} alt="Andrew Neil" />
//             </div>
//             <div className="details">
//               <span className="name">Andrew Neil</span>
//               <p className="text-sm">
//                 Highly skilled web developer, great results every time!
//               </p>
//             </div>
//           </div>
//           {/* <!-- rating stars --> */}
//           <div className="flex items-center">
//             <svg
//               className="w-4 h-4 text-yellow-300"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="currentColor"
//               viewBox="0 0 22 20"
//             >
//               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//             </svg>
//             <svg
//               className="w-4 h-4 text-yellow-300"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="currentColor"
//               viewBox="0 0 22 20"
//             >
//               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//             </svg>
//             <svg
//               className="w-4 h-4 text-yellow-300"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="currentColor"
//               viewBox="0 0 22 20"
//             >
//               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//             </svg>
//           </div>
//         </div>
//         {/* card  3*/}
//         <div className="card" style={{ "--delay": "1" }}>
//           <div className="content">
//             <div className="img">
//               <img src={man1} alt="Jasmine Carter" />
//             </div>
//             <div className="details">
//               <span className="name">Jasmine Carter</span>
//               <p className="text-sm">
//                 Highly skilled web developer, great results every time!
//               </p>
//             </div>
//           </div>
//           {/* <!-- rating stars --> */}
//           <div className="flex items-center">
//             <svg
//               className="w-4 h-4 text-yellow-300"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="currentColor"
//               viewBox="0 0 22 20"
//             >
//               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//             </svg>
//             <svg
//               className="w-4 h-4 text-yellow-300"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="currentColor"
//               viewBox="0 0 22 20"
//             >
//               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//             </svg>
//             <svg
//               className="w-4 h-4 text-yellow-300"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="currentColor"
//               viewBox="0 0 22 20"
//             >
//               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//             </svg>
//           </div>
//         </div>
//         {/* card  4*/}
//         <div className="card" style={{ "--delay": "2" }}>
//           <div className="content">
//             <div className="img">
//               <img src={man2} alt="Justin Chung" />
//             </div>
//             <div className="details">
//               <span className="name">Justin Chung</span>
//               <p className="text-sm">
//                 Highly skilled web developer, great results every time!
//               </p>
//             </div>
//           </div>
//           {/* <!-- rating stars --> */}
//           <div className="flex items-center">
//             <svg
//               className="w-4 h-4 text-yellow-300"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="currentColor"
//               viewBox="0 0 22 20"
//             >
//               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//             </svg>
//             <svg
//               className="w-4 h-4 text-yellow-300"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="currentColor"
//               viewBox="0 0 22 20"
//             >
//               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//             </svg>
//             <svg
//               className="w-4 h-4 text-yellow-300"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="currentColor"
//               viewBox="0 0 22 20"
//             >
//               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//             </svg>
//           </div>
//         </div>
//         {/* card  5*/}
//         <div className="card" style={{ "--delay": "3" }}>
//           <div className="content">
//             <div className="img">
//               <img src={man1} alt="Adrina Calvo" />
//             </div>
//             <div className="details">
//               <span className="name">Adrina Calvo</span>
//               <p className="text-sm">
//                 Highly skilled web developer, great results every time!
//               </p>
//             </div>
//           </div>
//           {/* <!-- rating stars --> */}
//           <div className="flex items-center">
//             <svg
//               className="w-4 h-4 text-yellow-300"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="currentColor"
//               viewBox="0 0 22 20"
//             >
//               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//             </svg>
//             <svg
//               className="w-4 h-4 text-yellow-300"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="currentColor"
//               viewBox="0 0 22 20"
//             >
//               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//             </svg>
//             <svg
//               className="w-4 h-4 text-yellow-300"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="currentColor"
//               viewBox="0 0 22 20"
//             >
//               <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//             </svg>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VerticalCardSlider;

//finally done
import React from "react";
import Slider from "react-slick";
import TestimonialCard from "./TestimonialCard";
import man1 from "../Assets/man1.png";
import man2 from "../Assets/man2.png";
import aribamemon from "../Assets/aribamemon.png";
import SarahWilliams from "../Assets/SarahWilliams.jpg";
import MichaelLee from "../Assets/MichaelLee.jpg";

function CenterMode() {
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    centerMode: true,
    focusOnSelect: true,
    infinite: true,
    centerPadding: "0px",
    initialSlide: 2,
    verticalSwiping: true,
    dots: true,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 2000,
  };

  // Testimonial data
  const testimonials = [
    {
      name: "John Doe",
      testimonial:
        "Working with Anum was an absolute pleasure! She brought our vision to life with amazing precision and attention to detail. Her technical skills combined with her creativity made the entire process smooth and enjoyable. Highly recommend",
      image: man2,
      rating: 5,
    },
    {
      name: "Ariba Memon",
      testimonial:
        "I am Ariba, the CEO of Digitalized Solutions, and I had the pleasure of having Anum as a student in the Fullstack development course at AtomCamp. Anum has been an outstanding member of our team at Digitalized Solutions, where she works as a frontend developer.",
      image: aribamemon,
      rating: 5,
    },
    {
      name: "Michael Lee",
      testimonial:
        "I’ve worked with several developers in the past, but Anum stands out for her professionalism and skill. She took our requirements and turned them into a polished, functional website that we’re proud of. Highly recommend her",
      image: MichaelLee,
      rating: 5,
    },
    {
      name: "Sara Williams",
      testimonial:
        "Anum exceeded our expectations on every level. From understanding our needs to delivering a beautifully functional website, her work is outstanding. She truly understands design and development and makes the whole experience seamless.",
      image: SarahWilliams,
      rating: 4,
    },
    {
      name: "David Brown",
      testimonial:
        "Anum is a game-changer! She made our project a reality in record time and the end result was flawless. Her technical expertise and dedication to user experience were evident in every aspect of the project. We couldn’t be happier",
      image: man1,
      rating: 5,
    },
  ];

  return (
    <div className="slider-container  ">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="px-4">
            {" "}
            {/* Increased spacing */}
            <TestimonialCard
              name={testimonial.name}
              testimonial={testimonial.testimonial}
              image={testimonial.image}
              rating={testimonial.rating}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CenterMode;
