// import React from "react";
// import person from "../Assets/person.jpg";
// import { FaFacebookF } from "react-icons/fa";
// import { FaGithub } from "react-icons/fa";
// import { FaLinkedinIn } from "react-icons/fa";
// import { IoLogoInstagram } from "react-icons/io5";

// const Sidebar = () => {
//   return (
//     <div className="px-8 pt-8 pb-4 ">
//       {/* image section */}
//       <div className="flex items-center justify-center w-44 h-44 mt-4 border-4 border-orange-600 rounded-full ">
//         <img src={person} alt="person" className="rounded-full w-40 h-40 " />
//       </div>
//       <h1 className="text-xl font-semibold text-center text-white mt-4 mb-7 font-Poppins">
//         Anum Shahana
//       </h1>
//       {/* content section */}
//   <div className="flex flex-col items-center justify-center text-white mt-4 font-Poppins ">
//     <h1 className="mb-2">Home</h1>
//     <h1 className="mb-2">About Me</h1>
//     <h1 className="mb-2">What I Do</h1>
//     <h1 className="mb-2">Resume</h1>
//     <h1 className="mb-2">Portfolio</h1>
//     <h1 className="mb-2">Testimonial</h1>
//     <h1 className="mb-2">Contact</h1>
//     <div className="flex space-x-2 pt-7 ">
//       <FaFacebookF />
//       <FaLinkedinIn />
//       <FaGithub />
//       <IoLogoInstagram />
//     </div>
//   </div>
//     </div>
//   );
// };

// export default Sidebar;

import React, { useState } from "react";
import logo2 from "../Assets/logo2.jpg";
import { FaFacebookF, FaGithub, FaLinkedinIn } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";

const Sidebar = ({ onMenuClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("Home");

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (menuItem) => {
    setSelectedMenu(menuItem);
    setIsOpen(false);
    onMenuClick(menuItem); // Pass the menu item to the parent component
  };

  return (
    <div className="relative">
      <div className="text-white flex justify-between items-center px-4 py-2 md:hidden">
        <div className="flex items-center space-x-2">
          <div className="flex items-center justify-center w-12 h-12 border-2 border-orange-600 rounded-full">
            {/* <img
              src={person}
              alt="Profile"
              className="rounded-full w-10 h-10"
            /> */}
            <img src={logo2} alt="Profile" className="rounded-full w-10 h-10" />
          </div>
          <h1 className="text-xl font-semibold">Anum Shahana</h1>
        </div>
        <div
          className="flex h-10 w-10 cursor-pointer items-center justify-center"
          onClick={toggleSidebar}
        >
          <div className="space-y-1">
            <span
              className={`block h-1 w-8 rounded-full bg-slate-500 transition-transform ${
                isOpen ? "rotate-45 translate-y-2" : ""
              }`}
            ></span>
            <span
              className={`block h-1 w-8 rounded-full bg-orange-500 transition-opacity ${
                isOpen ? "opacity-0" : ""
              }`}
            ></span>
            <span
              className={`block h-1 w-8 rounded-full bg-slate-500 transition-transform ${
                isOpen ? "-rotate-45 -translate-y-2" : ""
              }`}
            ></span>
          </div>
        </div>
      </div>

      <div
        className={`fixed top-0 left-0 h-full w-64 bg-neutral-900 px-8 pt-8 pb-4 md:flex flex-col items-center justify-start z-50 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <div className="hidden md:block">
          <div className="flex items-center justify-center w-44 h-44 border-4 border-orange-600 rounded-full">
            {/* <img
              src={person}
              alt="Profile"
              className="rounded-full w-40 h-40"
            /> */}
            <img src={logo2} alt="Profile" className="rounded-full w-40 h-40" />
          </div>
          <h1 className="text-xl text-center font-semibold text-white mt-4 mb-7">
            Anum Shahana
          </h1>
        </div>

        <div className="flex flex-col items-center text-white mt-4">
          {[
            "Home",
            "About Me",
            "What I Do",
            "Resume",
            "Portfolio",
            "Certificates",
            "Testimonial",
            "Contact",
          ].map((menuItem) => (
            <h1
              key={menuItem}
              className={`mb-2 cursor-pointer ${
                selectedMenu === menuItem ? "text-orange-600" : ""
              } hover:text-orange-600`}
              onClick={() => handleMenuClick(menuItem)}
            >
              {menuItem}
            </h1>
          ))}
          <div className="flex space-x-2 pt-7">
            <FaFacebookF />
            <FaLinkedinIn />
            <FaGithub />
            <IoLogoInstagram />
          </div>
        </div>
      </div>

      {isOpen && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-40"
          onClick={toggleSidebar}
        />
      )}
    </div>
  );
};

export default Sidebar;
